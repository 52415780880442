@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .container {
        max-width: 1280px;
        margin: 0 auto;
        padding: 0 16px;
        @media screen and (max-width: 48em) {
            padding: 0 12px;
        }
    }
    .flex-center {
        @apply flex items-center justify-center;
    }
    .fa-center {
        @apply flex items-center;
    }
    .facb {
        @apply flex items-center justify-between;
    }
}
